import React, { useState, useEffect } from 'react';

const Authentication = () => {
  const [input_username, setInputUsername] = useState("wcx01");
  const [input_password, setInputPassword] = useState("abc123");
  const [input_message, setInputMessage] = useState("");
  localStorage.setItem("token", "")
  localStorage.setItem("permission", "")

  useEffect(() => {
  }, []);

  const AuthenticationSubmit = async () => {
    var json = {
      "username": input_username,
      "password": input_password
    }

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(json)
    }).then((response) => response.json()).then((data) => {
      if (data.status === "200") {
        localStorage.setItem("token", data.token)
        localStorage.setItem("permission", data.permission)
        window.location.href = "Video"
      } else {
        setInputMessage(data.msg)
      }
    }).catch((err) => {
      console.log(err.message);
    });
  };

  return <>
    <div className="authentication-section">
      <div className="authentication-frame p-5">
        <img alt="scb_logo" className="banner m-auto p-3" src="images/banner.svg" />
        <h4 className="mt-3">渣打外汇智能专家</h4>
        <h4>内容管理系统</h4>
        <div className="text-danger mt-5">{input_message}</div>
        <input id="authentication-username" placeholder="Username" className="form-control mt-3" onChange={(e) => { setInputUsername(e.target.value) }} />
        <input id="authentication-password" type="password" placeholder="Password" className="form-control mt-3" onChange={(e) => { setInputPassword(e.target.value) }} />
        <button onClick={() => AuthenticationSubmit()} className="btn btn-primary form-control mt-3" type="button">Login</button>
      </div>
    </div>
  </>
};

export default Authentication;