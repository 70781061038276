import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

const ChangePassword = () => {
  const [input_password, setInputPassword] = useState("");
  const [input_new_password, setInputNewPassword] = useState("");
  const [input_confirm_password, setInputConfirmPassword] = useState("");
  const [input_message, setInputMessage] = useState("");

  useEffect(() => {
  }, []);

  const ChangePasswordSubmit = async () => {
    var json = {
      "username": localStorage.getItem("username"),
      "oldpassword": input_password,
      "newpassword": input_new_password,
      "token":localStorage.getItem("token")
    }
    var msg
    msg = ''
    if (input_new_password.length<6) {msg = "Password cannot be less than 6 characters"}
    if (input_new_password == input_password) {msg = "New password cannot be the same as the old password"}
    if (input_new_password !== input_confirm_password) {msg = "new and confirm password not the same"}
    if (msg != '') {
      setInputMessage(msg)
    } else {
      fetch(process.env.REACT_APP_API_BASE_URL + "/api/changepwd", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(json)
      }).then((response) => response.json()).then((data) => {
        if (data.status === "200") {
          localStorage.setItem("token", data.token)
          alert("Password changed successfully")
          window.location.href = "Video"
        } else {
          localStorage.setItem("token", data.token)
          setInputMessage(data.msg)
        }
      }).catch((err) => {
        console.log(err.message);
      });
    }
  };

  const ChangePasswordCancel = async () => {
    window.location.href = "Video"
  };

  return <>
    <div className="changepassword-section">
      <div className="changepassword-frame p-5">
        <img alt="scb_logo" className="banner m-auto p-3" src="images/banner.svg" />
        <h4 className="mt-3">渣打外汇智能专家</h4>
        <h4>内容管理系统</h4>
        <h4>更改用户密码</h4>
        <div className="text-danger mt-5">{input_message}</div>
        <input id="old-password" type="password" placeholder="Current Password" className="form-control mt-3" onChange={(e) => { setInputPassword(e.target.value) }} />
        <input id="new-password" type="password" placeholder="New Password" className="form-control mt-3" onChange={(e) => { setInputNewPassword(e.target.value) }} />
        <input id="confirm-password" type="password" placeholder="Confirm Password" className="form-control mt-3" onChange={(e) => { setInputConfirmPassword(e.target.value) }} />
        <button onClick={() => ChangePasswordSubmit()} onMouseDown={e => e.preventDefault()} className="btn btn-primary form-control mt-3" type="button">Change Password</button>
        <button onClick={() => ChangePasswordCancel()} className="btn btn-success form-control mt-3" type="button">Cancel</button>
      </div>
    </div>
  </>
};

export default ChangePassword;
