import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Authentication from "./pages/Authentication.jsx";
import ChangePassword from "./pages/ChangePassword.jsx";
import Video from "./pages/Video.jsx";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Authentication />} />
        <Route path="video" element={<Video />} />
        <Route path="ChangePassword" element={<ChangePassword />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
