import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

function getDateItem(data) {
  if (data.length === 8) {
    return (
      <option key={data} value={data}>{data.substring(0, 4)}-{data.substring(4, 6)}-{data.substring(6)}</option>
    );
  } else {
    return (
      <option key={data} value={data}>{data}</option>
    );
  }
}

function getViewsItem(index, ccy1, ccy2, cnview) {
  return (
    <option key={index} value={index}>{ccy1} / {ccy2} ({cnview})</option>
  );
}

const Video = () => {
  const [lang, setLang] = useState("zn-CN");
  const [input_date, setInputDate] = useState("");
  const [input_expect, setExpect] = useState("");
  const [input_entry, setEntry] = useState("");
  const [input_neutral, setNeutral] = useState("");
  const [input_ccy1, setInputCcy1] = useState("");
  const [input_ccy2, setInputCcy2] = useState("");
  const [input_eng_ccy1, setInputEngCcy1] = useState("");
  const [input_eng_ccy2, setInputEngCcy2] = useState("");
  const [input_cnview, setInputCnview] = useState("");
  const [input_eng_cnview, setInputEngCnview] = useState("");
  const [input_amended_msg1_1, setInputAmendedMsg1_1] = useState("");
  const [input_amended_msg1_2, setInputAmendedMsg1_2] = useState("");
  const [input_amended_msg2, setInputAmendedMsg2] = useState("");
  const [input_amended_eng_msg1_1, setInputAmendedEngMsg1_1] = useState("");
  const [input_amended_eng_msg1_2, setInputAmendedEngMsg1_2] = useState("");
  const [input_amended_eng_msg2, setInputAmendedEngMsg2] = useState("");
  const [input_json, setInputJSON] = useState([]);
  const [input_view, setView] = useState(0);
  const [input_active, setInputActive] = useState(true);
  const [input_datelist, setDateList] = useState([]);
  const [input_message, setInputMessage] = useState("");
  const [input_message2, setInputMessage2] = useState("");
  const [videoname, setVideoName] = useState("")
  const [changestatus, setChangeStatus] = useState(false)
  const [loadingstatus, setLoadingStatus] = useState(false)
  const [temptype, setTempType] = useState("")
  const [tempselectedoption, setTempSelectedOption] = useState("")
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const maxCNLength = 60
  const maxENLength = 300

  var myInterval;

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + "/api/listdate?token=" + localStorage.getItem("token"), {
    }).then((response) => response.json()).then((result) => {
      if (result.status === "200") {
        localStorage.setItem("token", result.token)
        setDateList(result.data)
        HandleDateSelector(result.data[0])
      } else {
        window.location.href = "/"
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }, []);


  const checkVideoUrl = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      console.log(`狀態碼: ${response.status}`);
      if (response.status === 200) {
        console.log(url)
        document.getElementById('video-iframe').src = url
      } else {
        document.getElementById('video-iframe').src = ""
      }
    } catch (error) {
      document.getElementById('video-iframe').src = ""
    }
  };

  useEffect(() => {
    checkVideoUrl(videoname.replace('.mp4', '_preview.mp4'+ '?timestamp=' + new Date().getTime()));
    CheckFirstTimeStatus();
  }, [videoname]);

  const StartCheckStatus = async () => {
    myInterval = setInterval(() => {
      console.log("check status")
      CheckStatus();
    }, "10000");
  }

  const CheckFirstTimeStatus = async () => {
    console.log("FIRST TIME CHECKING")
    checkVideoUrl(videoname.replace('.mp4', '_preview.mp4'+ '?timestamp=' + new Date().getTime()));
    setInputMessage2('')
  };

  const CheckStatus = async () => {
    fetch(process.env.REACT_APP_API_BASE_URL + "/api2?status=" + lang + input_date + input_view, {
    }).then((response) => response.json()).then((result) => {
      var res
      res = result.status
      if (res == "ready") {
        res = "submitted"
      }
      setInputMessage2(res)
      if (res == "complete") {
        clearInterval(myInterval);
        checkVideoUrl(videoname.replace('.mp4', '_preview.mp4'+ '?timestamp=' + new Date().getTime()));
      }
    }).catch((err) => {
      console.log(err.message);
    });
  };

  const LogoutButton = async () => {
    window.location.href = "/"
  };

  const RefreshButton = async () => {
    checkVideoUrl(videoname.replace('.mp4', '_preview.mp4'+ '?timestamp=' + new Date().getTime()));
  };

  const DownloadButton = async () => {
    console.log(videoname)
    navigator.clipboard.writeText(videoname);
    alert("Download link copied to clipboard");
  };

  const GenerateButton = async () => {
    if (loadingstatus == false) {
      setLoadingStatus(true)
      fetch(process.env.REACT_APP_API_BASE_URL + "/api2?date=" + input_date + "&view=" + input_view + "&lang=" + lang, {
      }).then((response) => response).then((result) => {
        console.log(result)
        if (result.status === 200) {
          setInputMessage2("submitting")
          StartCheckStatus();
          setInputMessage("Video is being generated")
        } else {
          setInputMessage("Fail to generate the video")
        }
        setLoadingStatus(false)
      }).catch((err) => {
        console.log(err.message);
        setInputMessage("Fail to generate the video")
        setLoadingStatus(false)
      });
    }
  };

  const UpdateButton = async () => {
    setChangeStatus(false)
    input_json.views[input_view].expect = input_expect
    input_json.views[input_view].entry = input_entry
    input_json.views[input_view].neutral = input_neutral

    input_json.views[input_view].ccy1 = input_ccy1
    input_json.views[input_view].ccy2 = input_ccy2
    input_json.views[input_view].cnview = input_cnview
    input_json.views[input_view].amended_msg1 = input_amended_msg1_1 + ((input_amended_msg1_1.slice(-1)=="。") ? "" : "。") + input_amended_msg1_2
    input_json.views[input_view].amended_msg2 = input_amended_msg2

    input_json.views[input_view].eng_ccy1 = input_eng_ccy1
    input_json.views[input_view].eng_ccy2 = input_eng_ccy2
    input_json.views[input_view].view = input_eng_cnview
    input_json.views[input_view].amended_eng_msg1 = input_amended_eng_msg1_1 
                                                    + ((input_amended_eng_msg1_1.slice(-2)==". ")?"":((input_amended_eng_msg1_1.slice(-1)==".") ? " ":". "))
                                                    + input_amended_eng_msg1_2
    input_json.views[input_view].amended_eng_msg2 = input_amended_eng_msg2

    console.log(input_json)

    fetch(process.env.REACT_APP_API_BASE_URL + "/api/savejson?token=" + localStorage.getItem("token") + "&date=" + input_date + "&data=" + encodeURIComponent(JSON.stringify(input_json)), {
    }).then((response) => response.json()).then((result) => {
      if (result.status === "200") {
        localStorage.setItem("token", result.token)
        setInputMessage("Updated")
      } else {
        window.location.href = "/"
      }
    }).catch((err) => {
      console.log(err.message);
    });
  };

  const ConfirmModeal = async (type, data) => {
    setTempType(type)
    setTempSelectedOption(data)

    if (changestatus == true) {
      setDeleteModalShow(true)
    } else {
      if (type == "date")
        HandleDateSelector(data)
      else if (type == "view")
        HandleViewsSelector(data)
    }
  };

  const HandleDateSelector = async (date) => {
    clearInterval(myInterval);
    setInputMessage("")
    setInputMessage2("")
    setInputDate(date)
    setChangeStatus(false)
    setLoadingStatus(false)
    fetch(process.env.REACT_APP_API_BASE_URL + "/api/getjson?token=" + localStorage.getItem("token") + "&date=" + date, {
    }).then((response) => response.json()).then((result) => {
      if (result.status === "200") {
        localStorage.setItem("token", result.token)
        setInputJSON(result.data)

        if (result.data.views.length > 0) {
          setView(0)
          setInputActive(true)

          if (result.data.views[0].status == "Open")
            setInputActive(true)
          else
            setInputActive(false)

          setExpect(result.data.views[0].expect);
          setEntry(result.data.views[0].entry);
          setNeutral(result.data.views[0].neutral);

          setInputCcy1(result.data.views[0].ccy1)
          setInputCcy2(result.data.views[0].ccy2)
          setInputCnview(result.data.views[0].cnview)

          setInputEngCcy1(result.data.views[0].eng_ccy1)
          setInputEngCcy2(result.data.views[0].eng_ccy2)
          setInputEngCnview(result.data.views[0].view)

          if (lang == "en")
            setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + date + "0_en_video.mp4")
          else
            setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + date + "0_video.mp4")

          if (result.data.views[0].amended_msg1 == "") {
            setInputAmendedMsg1_1(result.data.views[0].msg1.split("。")[0]+"。")
            setInputAmendedMsg1_2(result.data.views[0].msg1.split("。")[1]+"。")
          } else {
            setInputAmendedMsg1_1(result.data.views[0].amended_msg1.split("。")[0]+"。")
            setInputAmendedMsg1_2(result.data.views[0].amended_msg1.split("。")[1]+"。")
          }

          if (result.data.views[0].amended_msg2 == "")
            setInputAmendedMsg2(result.data.views[0].msg2)
          else
            setInputAmendedMsg2(result.data.views[0].amended_msg2)

          if (result.data.views[0].amended_eng_msg1 == "") {
            setInputAmendedEngMsg1_1(result.data.views[0].eng_msg1.split(". ")[0]+".")
            setInputAmendedEngMsg1_2(result.data.views[0].eng_msg1.split(". ")[1])
          } else {
            setInputAmendedEngMsg1_1(result.data.views[0].amended_eng_msg1.split(". ")[0]+".")
            setInputAmendedEngMsg1_2(result.data.views[0].amended_eng_msg1.split(". ")[1])
          }

          if (result.data.views[0].amended_eng_msg2 == "")
            setInputAmendedEngMsg2(result.data.views[0].eng_msg2)
          else
            setInputAmendedEngMsg2(result.data.views[0].amended_eng_msg2)
        } else {
          setInputJSON([])
          setInputActive(false)
          setExpect("");
          setEntry("");
          setNeutral("");
          setInputCcy1("")
          setInputCcy2("")
          setInputCnview("")
          setInputAmendedMsg1_1("")
          setInputAmendedMsg1_2("")
          setInputAmendedMsg2("")
          setInputEngCcy1("")
          setInputEngCcy2("")
          setInputEngCnview("")
          setInputAmendedEngMsg1_1("")
          setInputAmendedEngMsg1_2("")
          setInputAmendedEngMsg2("")

          if (lang == "en")
            setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + date + "0_en_video.mp4")
          else
            setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + date + "0_video.mp4")
        }
      } else {
        window.location.href = "/"
      }
    }).catch((err) => {
      console.log(err.message);
    });
  };

  const HandleViewsSelector = async (index) => {
    clearInterval(myInterval);
    document.activeElement?.blur && document.activeElement.blur()

    setInputMessage("")
    setInputMessage2("")
    setChangeStatus(false)
    setView(index)

    if (lang == "en")
      setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + input_date + index + "_en_video.mp4")
    else
      setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + input_date + index + "_video.mp4")

    if (input_json.views[index].status == "Open")
      setInputActive(true)
    else
      setInputActive(false)

    setExpect(input_json.views[index].expect);
    setEntry(input_json.views[index].entry);
    setNeutral(input_json.views[index].neutral);

    setInputCcy1(input_json.views[index].ccy1)
    setInputCcy2(input_json.views[index].ccy2)
    setInputCnview(input_json.views[index].cnview)

    setInputEngCcy1(input_json.views[index].eng_ccy1)
    setInputEngCcy2(input_json.views[index].eng_ccy2)
    setInputEngCnview(input_json.views[index].view)

    if (input_json.views[index].amended_msg1 == "") {
      setInputAmendedMsg1_1(input_json.views[index].msg1.split("。")[0]+"。")
      setInputAmendedMsg1_2(input_json.views[index].msg1.split("。")[1]+"。")
    } else {
      setInputAmendedMsg1_1(input_json.views[index].amended_msg1.split("。")[0]+"。")
      setInputAmendedMsg1_2(input_json.views[index].amended_msg1.split("。")[1]+"。")
    }

    if (input_json.views[index].amended_msg2 == "")
      setInputAmendedMsg2(input_json.views[index].msg2)
    else
      setInputAmendedMsg2(input_json.views[index].amended_msg2)

    if (input_json.views[index].amended_eng_msg1 == "") {
      setInputAmendedEngMsg1_1(input_json.views[index].eng_msg1.split(". ")[0]+".")
      setInputAmendedEngMsg1_2(input_json.views[index].eng_msg1.split(". ")[1])
    } else {
      setInputAmendedEngMsg1_1(input_json.views[index].amended_eng_msg1.split(". ")[0]+".")
      setInputAmendedEngMsg1_2(input_json.views[index].amended_eng_msg1.split(". ")[1])
    }

    if (input_json.views[index].amended_eng_msg2 == "")
      setInputAmendedEngMsg2(input_json.views[index].eng_msg2)
    else
      setInputAmendedEngMsg2(input_json.views[index].amended_eng_msg2)
  };

  function CancelButtonUI() {
    return (
      <div className="col-4">
        <button onClick={() => HandleViewsSelector(input_view)} className="btn btn-warning form-control mt-4" type="button">Cancel</button>
      </div>
    )
  }

  function DownloadButtonUI(value) {
    if (value == true)
      return (
        <div className="col-4">
          <button onClick={() => DownloadButton()} className="btn btn-success form-control mt-4" type="button">Download</button>
        </div>
      )
    else
      return (
        <div className="col-4">
          <button disabled className="btn btn-success form-control mt-4" type="button">Download</button>
        </div>
      )
  }

  function GenerateButtonUI(value) {
    if (value == true)
      return (
        <div className="col-4">
          <button onClick={() => GenerateButton()} className="btn btn-success form-control mt-4" type="button">Generate</button>
        </div>
      )
    else
      return (
        <div className="col-4">
          <button disabled className="btn btn-success form-control mt-4" type="button">Generate</button>
        </div>
      )
  }

  function UpdateButtonUI(value) {
    if (value == true)
      return (

        <div className="col-4">
          <button onClick={() => UpdateButton()} className="btn btn-primary form-control mt-4" type="button">Update</button>
        </div>
      )
    else
      return (

        <div className="col-4">
          <button disabled className="btn btn-primary form-control mt-4" type="button">Update</button>
        </div>
      )
  }

  function DeleteModalConfirmHandle() {
    setDeleteModalShow(false)
    setChangeStatus(false)

    if (temptype == "date")
      HandleDateSelector(tempselectedoption)
    else if (temptype == "view")
      HandleViewsSelector(tempselectedoption)
  }


  function  maxLengthCheck(object) {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0,object.target.maxLength)
    }
  }


  function checkingIframe(iframe) {
    console.log(iframe)
  }

  function DeleteModal(props) {
    return (
      <Modal
        {...props}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            <i className="bi bi-exclamation-circle text-danger display-3"></i>
            <h5 className="mt-3">Are you sure to proceed and discard the changes</h5>
          </div>
          <div className="text-center mt-3 row">
            <div className="col-6">
              <button onClick={props.onHide} className="btn btn-success form-control mt-4" type="button">Cancel</button>
            </div>
            <div className="col-6">
              <button onClick={() => DeleteModalConfirmHandle()} className="btn btn-primary form-control mt-4" type="button">Confirm</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return <>
    <DeleteModal
      show={deleteModalShow}
      onHide={() => setDeleteModalShow(false)}
    />

    <div className="video-section">
      <div className="row video-frame">
        <div className="col-12 pt-5 px-5 position-relative">
          <button onClick={() => LogoutButton()} className="btn btn-warning btn-logout position-absolute" type="button">Logout</button>
          <img alt="scb_logo" className="banner m-auto" src="images/banner.svg" />
          <div className="text-danger mt-5">{input_message}</div>
          <div className="text-danger mt-3">{input_message2}</div>
        </div>
        <div className="col-12 col-lg-6 p-5">
          {/* <button onClick={() => RefreshButton()} className="btn btn-warning mb-3" type="button">Refresh</button> */}
          <iframe title="demo" className="video-iframe" id="video-iframe"></iframe>
        </div>
        <div className="col-12 col-lg-6 p-5">
          <div className="row">
            <div className="col-12 col-lg-4">
              <select className="form-select" value={lang} onChange={(e) => {
                setLang(e.target.value);

                if (e.target.value == "en")
                  setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + input_date + input_view + "_en_video.mp4")
                else
                  setVideoName(process.env.REACT_APP_VIDEO_URL + "/" + input_date + input_view + "_video.mp4")
              }}>
                <option value="zn-CN">中文</option>
                <option value="en">English</option>
              </select>
            </div>
            <div className="col-12 col-lg-4">
              <select className="form-select" value={input_date} onChange={(e) => { ConfirmModeal("date", e.target.value) }}>
                {
                  input_datelist.map((data, index) => (
                    getDateItem(data)
                  ))
                }
              </select>
            </div>

            {(input_active == true && localStorage.getItem("permission") == "W" ?
              <>
                <div className="col-12 col-lg-4">
                  <select className="form-select" value={input_view} onChange={(e) => { ConfirmModeal("view", e.target.value) }}>
                    {(lang == "zn-CN" ?
                      input_json?.views?.map((data, index) => (
                        getViewsItem(index, data.ccy1, data.ccy2, data.cnview)
                      ))
                      :
                      input_json?.views?.map((data, index) => (
                        getViewsItem(index, data.eng_ccy1, data.eng_ccy2, data.view)
                      ))

                    )}
                  </select>
                </div>


                {(lang == "zn-CN" ?
                  <>
                    <div className="col-4 mt-4">
                      <span>入市价</span>
                      <input id="video-entry" placeholder="入市价" className="form-control mt-2" value={input_entry} onChange={(e) => { setEntry(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>止损价</span>
                      <input id="video-neutral " placeholder="止损价" className="form-control mt-2" value={input_neutral} onChange={(e) => { setNeutral(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>获利价</span>
                      <input id="video-expect" placeholder="获利价" className="form-control mt-2" value={input_expect} onChange={(e) => { setExpect(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-ccy1" placeholder="货币 1" className="form-control mt-4" value={input_ccy1} onChange={(e) => { setInputCcy1(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-ccy2" placeholder="货币 2" className="form-control mt-4" value={input_ccy2} onChange={(e) => { setInputCcy2(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-cnview" placeholder="看法" className="form-control mt-4" value={input_cnview} onChange={(e) => { setInputCnview(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_msg1_1" className="form-control mt-4 video-text-area" value={input_amended_msg1_1} placeholder="描述 1 - 第一句" maxLength={maxCNLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg1_1(e.target.value); setChangeStatus(true) }}></textarea>
                      <span>共{input_amended_msg1_1.length}/{maxCNLength}字</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_msg1_2" className="form-control mt-4 video-text-area" value={input_amended_msg1_2} placeholder="描述 1 - 第二句" maxLength={maxCNLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg1_2(e.target.value); setChangeStatus(true) }}></textarea>
                      <span>共{input_amended_msg1_2.length}/{maxCNLength}字</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_msg2" className="form-control mt-4 video-text-area" value={input_amended_msg2} placeholder="描述 2" maxLength={maxCNLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg2(e.target.value); setChangeStatus(true) }}></textarea>
                      <span>共{input_amended_msg2.length}/{maxCNLength}字</span>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-4 mt-4">
                      <span>Entry</span>
                      <input id="video-entry" placeholder="Entry" className="form-control mt-2" value={input_entry} onChange={(e) => { setEntry(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>Neutral </span>
                      <input id="video-neutral " placeholder="Neutral " className="form-control mt-2" value={input_neutral} onChange={(e) => { setNeutral(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>Expect</span>
                      <input id="video-expect" placeholder="Expect" className="form-control mt-2" value={input_expect} onChange={(e) => { setExpect(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-eng-ccy1" placeholder="Currency 1" className="form-control mt-4" value={input_eng_ccy1} onChange={(e) => { setInputEngCcy1(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-eng-ccy2" placeholder="Currency 2" className="form-control mt-4" value={input_eng_ccy2} onChange={(e) => { setInputEngCcy2(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-eng-cnview" placeholder="View" className="form-control mt-4" value={input_eng_cnview} onChange={(e) => { setInputEngCnview(e.target.value); setChangeStatus(true) }} />
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_eng_msg1_1" className="form-control mt-4 video-text-area" value={input_amended_eng_msg1_1} placeholder="Message 1 - 1st Sentence" maxLength={maxENLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedEngMsg1_1(e.target.value); setChangeStatus(true) }}></textarea>
                      <span>{input_amended_eng_msg1_1.length}/{maxENLength} characters</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_eng_msg1_2" className="form-control mt-4 video-text-area" value={input_amended_eng_msg1_2} placeholder="Message 1 - 2nd Sentence" maxLength={maxENLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedEngMsg1_2(e.target.value); setChangeStatus(true) }}></textarea>
                      <span>{input_amended_eng_msg1_2.length}/{maxENLength} characters</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_eng_msg2" className="form-control mt-4 video-text-area" value={input_amended_eng_msg2} placeholder="Message 2" maxLength={maxENLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedEngMsg2(e.target.value); setChangeStatus(true) }}></textarea>
                      <span>{input_amended_eng_msg2.length}/{maxENLength} characters</span>
                    </div>
                  </>
                )}

                {(changestatus == false ?
                  <>

                    {DownloadButtonUI(true)}
                    {(loadingstatus == false ?
                      <>
                        {GenerateButtonUI(true)}
                      </>
                      :
                      <>
                        {GenerateButtonUI(false)}
                      </>
                    )}
                    {UpdateButtonUI(false)}
                  </>
                  :
                  <>
                    {DownloadButtonUI(false)}
                    {CancelButtonUI()}
                    {UpdateButtonUI(true)}
                  </>
                )}
              </>
              :
              <>
                <div className="col-12 col-lg-4">
                  <select className="form-select" onChange={(e) => { HandleViewsSelector(e.target.value) }}>
                    {(lang == "zn-CN" ?
                      input_json?.views?.map((data, index) => (
                        getViewsItem(index, data.ccy1, data.ccy2, data.cnview)
                      ))
                      :
                      input_json?.views?.map((data, index) => (
                        getViewsItem(index, data.eng_ccy1, data.eng_ccy2, data.view)
                      ))
                    )}
                  </select>
                </div>

                {(lang == "zn-CN" ?
                  <>
                    <div className="col-4 mt-4">
                      <span>入市价</span>
                      <input id="video-entry" disabled placeholder="入市价" className="form-control mt-2" value={input_entry} onChange={(e) => { setEntry(e.target.value); }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>止损价</span>
                      <input id="video-neutral " disabled placeholder="止损价" className="form-control mt-2" value={input_neutral} onChange={(e) => { setNeutral(e.target.value); }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>获利价</span>
                      <input id="video-expect" disabled placeholder="获利价" className="form-control mt-2" value={input_expect} onChange={(e) => { setExpect(e.target.value); }} />
                    </div>
                    <div className="col-4">
                      <input id="video-ccy1" disabled placeholder="货币 1" className="form-control mt-4" value={input_ccy1} onChange={(e) => { setInputCcy1(e.target.value) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-ccy2" disabled placeholder="货币 2" className="form-control mt-4" value={input_ccy2} onChange={(e) => { setInputCcy2(e.target.value) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-cnview" disabled placeholder="看法" className="form-control mt-4" value={input_cnview} onChange={(e) => { setInputCnview(e.target.value) }} />
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_msg1_1" disabled className="form-control mt-4 video-text-area" value={input_amended_msg1_1} placeholder="描述 1 - 第一句" maxLength={maxCNLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg1_1(e.target.value) }}></textarea>
                      <span>共{input_amended_msg1_1.length}/{maxCNLength}字</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_msg1_2" disabled className="form-control mt-4 video-text-area" value={input_amended_msg1_2} placeholder="描述 1 - 第二句" maxLength={maxCNLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg1_2(e.target.value) }}></textarea>
                      <span>共{input_amended_msg1_2.length}/{maxCNLength}字</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_msg2" disabled className="form-control mt-4 video-text-area" value={input_amended_msg2} placeholder="描述 2" maxLength={maxCNLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg2(e.target.value) }}></textarea>
                      <span>共{input_amended_msg2.length}/{maxCNLength}字</span>
                    </div>
                  </>
                  :
                  <>
                    <div className="col-4 mt-4">
                      <span>Entry</span>
                      <input id="video-entry" disabled placeholder="Entry" className="form-control mt-2" value={input_entry} onChange={(e) => { setEntry(e.target.value); }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>Neutral </span>
                      <input id="video-neutral " disabled placeholder="Neutral " className="form-control mt-2" value={input_neutral} onChange={(e) => { setNeutral(e.target.value); }} />
                    </div>
                    <div className="col-4 mt-4">
                      <span>Expect</span>
                      <input id="video-expect" disabled placeholder="Expect" className="form-control mt-2" value={input_expect} onChange={(e) => { setExpect(e.target.value); }} />
                    </div>
                    <div className="col-4">
                      <input id="video-ccy1" disabled placeholder="Currency 1" className="form-control mt-4" value={input_eng_ccy1} onChange={(e) => { setInputCcy1(e.target.value) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-ccy2" disabled placeholder="Currency 2" className="form-control mt-4" value={input_eng_ccy2} onChange={(e) => { setInputCcy2(e.target.value) }} />
                    </div>
                    <div className="col-4">
                      <input id="video-cnview" disabled placeholder="View" className="form-control mt-4" value={input_eng_cnview} onChange={(e) => { setInputCnview(e.target.value) }} />
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_eng_msg1_1" disabled className="form-control mt-4 video-text-area" value={input_amended_eng_msg1_1} placeholder="Message 1 - 1st Sentence" maxLength={maxENLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg1_1(e.target.value) }}></textarea>
                      <span>{input_amended_eng_msg1_1.length}/{maxENLength} characters</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_eng_msg1_2" disabled className="form-control mt-4 video-text-area" value={input_amended_eng_msg1_2} placeholder="Message 1 - 2nd Sentence" maxLength={maxENLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg1_2(e.target.value) }}></textarea>
                      <span>{input_amended_eng_msg1_2.length}/{maxENLength} characters</span>
                    </div>
                    <div className="col-12">
                      <textarea id="video-amended_eng_msg2" disabled className="form-control mt-4 video-text-area" value={input_amended_eng_msg2} placeholder="Message 2" maxLength={maxENLength} onInput={maxLengthCheck} onChange={(e) => { setInputAmendedMsg2(e.target.value) }}></textarea>
                      <span>{input_amended_eng_msg2.length}/{maxENLength} characters</span>
                    </div>
                  </>
                )}

                {(input_active == true ?
                  <>
                    {DownloadButtonUI(true)}
                    {GenerateButtonUI(true)}
                    {UpdateButtonUI(false)}
                  </>
                  :
                  <>
                    {DownloadButtonUI(false)}
                    {GenerateButtonUI(false)}
                    {UpdateButtonUI(false)}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div >
  </>
};

export default Video;
